import "./styles.scss"

import React from "react"
import Slider from "react-slick"

const References = ({ data }) => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  }

  return (
    <section className="home-references">
      <div className="container-fluid">
        <h2>Opinie</h2>
        <Slider {...slider} className="slick-slider--arrows-big">
          {data?.referencesContent?.map((item, index) => (
            <div key={index}>
              <div className="home-references__item">
                <h3>"{item.content}"</h3>
                {item.author && <p>{item.author}</p>}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

export default References
