import "./styles.scss"

import React from "react"

import Button from "components/Button"
import LazyFadeImage from "components/LazyFadeImage"

const Office = () => {
  return (
    <section className="home-office">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <h2>Zewnętrzne biuro sprzedaży projektów deweloperskich</h2>
            <p>
              Nie jesteśmy typową agencją nieruchomości. W kontakcie z
              Klientami, reprezentujemy deweloperów – tych działających na dużą
              skalę jak i lokalnych, renomowanych inwestorów. Dzięki temu, w
              naszej ofercie znajdziesz prawdziwe perełki z rynku nieruchomości
              premium, do których dostęp mają tylko nieliczni.
            </p>
            <p>
              Współpraca z nami to nie tylko wyjątkowe projekty, ale także
              najwyższa jakość obsługi. Budujemy długotrwałe relacje oparte na
              zaufaniu i wzajemnej satysfakcji.
            </p>
            <Button className="button--bordered" to="/dla-klientow/">
              Dowiedz się więcej
            </Button>
          </div>

          <div className="col-md-6 offset-xl-1">
            <LazyFadeImage>
              <div className="home-office__image"></div>
            </LazyFadeImage>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Office
