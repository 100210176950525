import "./styles.scss"

import React from "react"
import { Fade } from "react-awesome-reveal"

const Numbers = ({ data }) => {
  return (
    <>
      <span className="anchor" id="more"></span>
      <section className="home-numbers">
        <div className="container-fluid">
          <h2>Marshall Real Estate w liczbach</h2>
          <div className="home-numbers__row">
            {data?.numbersContent?.map((item, index) => (
              <Fade key={index} direction="up" delay={index * 150} triggerOnce>
                <div className="home-numbers__item">
                  <h4>
                    {item.number}
                    <div dangerouslySetInnerHTML={{ __html: item.label }} />
                  </h4>
                  <div dangerouslySetInnerHTML={{ __html: item.header }} />
                  <p>{item.description}</p>
                </div>
              </Fade>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default Numbers
