import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import ApartmentTile from "components/ApartmentTile"

import { useApartments } from "context/apartments"

const Apartments = () => {
  const { apartments } = useApartments()
  const { apartmentsStandalone } = useApartments()
  const apartments_combine = [...apartments, ...apartmentsStandalone]

  const apartments_featured = apartments_combine.filter(
    item => item.acfApartment.featured === true
  )

  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow:
      apartments_featured?.length === 1
        ? 1
        : apartments_featured?.length === 2
        ? 2
        : 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          slidesToShow: apartments_featured?.length === 1 ? 1 : 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    apartments_featured.length > 0 && (
      <section className="home-apartments">
        <div className="container-fluid">
          <h2>Oferty specjalne</h2>
          <Slider {...slider}>
            {apartments_featured?.map((item, index) => (
              <div key={index}>
                <ApartmentTile item={item} showAddress />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
  )
}

export default Apartments
