import "./styles.scss"

import React, { useState } from "react"
import { navigate } from "gatsby"

import Button from "components/Button"
import Input from "components/Input"
import Select from "components/Select"

import IcoShowMore from "assets/icons/show-more-arrow.svg"

const Search = () => {
  const [advancedSearch, setAdvancedSearch] = useState(false)

  const [filter, setFilter] = useState({
    areaStart: "",
    areaEnd: "",
    roomsStart: "",
    roomsEnd: "",
    floorsStart: "",
    floorsEnd: "",
    status: "",
    region: "",
    priceStart: "",
    priceEnd: "",
  })

  const formSubmit = e => {
    e.preventDefault()
    navigate(
      `/wyszukiwarka/?type=investment${
        filter.areaStart && `&area_start=${filter.areaStart}`
      }${filter.areaEnd && `&area_end=${filter.areaEnd}`}${
        filter.roomsStart && `&rooms_start=${filter.roomsStart}`
      }${filter.roomsEnd && `&rooms_end=${filter.roomsEnd}`}${
        filter.floorsStart && `&floors_start=${filter.floorsStart}`
      }${filter.floorsEnd && `&floors_end=${filter.floorsEnd}`}${
        filter.status && `&status=${filter.status}`
      }${filter.region && `&region=${filter.region}`}${
        filter.priceStart && `&price_start=${filter.priceStart}`
      }${filter.priceEnd && `&price_end=${filter.priceEnd}`}
      `
    )
  }

  return (
    <section className="home-search">
      <div className="container-fluid">
        <h2>Wyszukiwarka inwestycji</h2>
        <form onSubmit={formSubmit}>
          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Metraż od"
                placeholder={`20 m2`}
                onChange={e => {
                  setFilter({
                    ...filter,
                    areaStart: e.target.value,
                  })
                }}
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Metraż do"
                placeholder={`60 m2`}
                onChange={e =>
                  setFilter({
                    ...filter,
                    areaEnd: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Cena brutto od"
                placeholder="389 000 zł"
                onChange={e =>
                  setFilter({
                    ...filter,
                    priceStart: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Input
                type="number"
                className="input--bordered"
                label="Cena brutto do"
                placeholder="576 000 zł"
                onChange={e =>
                  setFilter({
                    ...filter,
                    priceEnd: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Select
                label="Region inwestycji"
                options={[
                  { label: "Wszystkie", value: [] },
                  { label: "Nad morzem", value: "sea" },
                  { label: "W górach", value: "mountains" },
                  { label: "Warmia i mazury", value: "lakes" },
                ]}
                onChange={e =>
                  setFilter({
                    ...filter,
                    region: e.target.value,
                  })
                }
              />
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <Button className="button--w-100">Wyszukaj</Button>
            </div>
          </div>

          <button
            className={`home-search__cta${
              advancedSearch ? " home-search__cta--more" : ""
            }`}
            onClick={e => {
              e.preventDefault()
              setAdvancedSearch(!advancedSearch)
            }}
          >
            Wyszukiwanie zaawansowane <img src={IcoShowMore} alt="" />
          </button>

          {advancedSearch && (
            <div className="row">
              <div className="col-lg-2 col-md-4 col-sm-6">
                <Input
                  type="number"
                  className="input--bordered"
                  label="Liczba pokoi od"
                  placeholder="2"
                  onChange={e => {
                    setFilter({
                      ...filter,
                      roomsStart: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <Input
                  type="number"
                  className="input--bordered"
                  label="Liczba pokoi do"
                  placeholder="4"
                  onChange={e =>
                    setFilter({
                      ...filter,
                      roomsEnd: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <Input
                  type="number"
                  className="input--bordered"
                  label="Piętro od"
                  placeholder="1"
                  onChange={e => {
                    setFilter({
                      ...filter,
                      floorsStart: e.target.value,
                    })
                  }}
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <Input
                  type="number"
                  className="input--bordered"
                  label="Piętro do"
                  placeholder="3"
                  onChange={e =>
                    setFilter({
                      ...filter,
                      floorsEnd: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <Select
                  label="Status inwestycji"
                  options={[
                    { label: "Wszystkie", value: [] },
                    { label: "W sprzedaży", value: "sell" },
                    { label: "Zakończone", value: "done" },
                  ]}
                  onChange={e =>
                    setFilter({
                      ...filter,
                      status: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  )
}

export default Search
