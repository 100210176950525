import "./styles.scss"

import React from "react"
import Slider from "react-slick"

import InvestmentTile from "components/InvestmentTile"

import { useInvestments } from "context/investments"

const Investments = () => {
  const { investments } = useInvestments()

  const investments_featured = investments.filter(
    item => item.acfInvestment.featured === true
  )

  const slider = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 750,
    slidesToShow:
      investments_featured?.length === 1
        ? 1
        : investments_featured?.length === 2
        ? 2
        : 3,
    slidesToScroll: 1,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: investments_featured?.length === 1 ? 1 : 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    investments_featured.length > 0 && (
      <section className="home-investments">
        <div className="container-fluid">
          <h2>Wybrane inwestycje</h2>
          <Slider {...slider}>
            {investments_featured.map((item, index) => (
              <div key={index}>
                <InvestmentTile variant="grid" item={item} />
              </div>
            ))}
          </Slider>
        </div>
      </section>
    )
  )
}

export default Investments
