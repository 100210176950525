import "./styles.scss"

import React, { useState } from "react"
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useJsApiLoader,
} from "@react-google-maps/api"
import { withPrefix } from "gatsby"

import InvestmentMapItem from "components/InvestmentMapItem"
import LazyFadeImage from "components/LazyFadeImage"

import mapStyle from "utils/mapStyle"

import { useInvestments } from "context/investments"

const center = {
  lat: 52.0497363,
  lng: 19.2851127,
}

const Map = () => {
  const isBrowser = typeof window !== "undefined"
  const { investments } = useInvestments()
  const [infoWindow, setInfoWindow] = useState(null)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDjIwkgdwELw8D7YSGfklubIKQyUl-OQy4",
  })

  return (
    <div className="home-map">
      <div className="container-fluid">
        <div className="home-map__row">
          <h2>Mapa inwestycji</h2>
          {/* <div className="home-map__filters">
            <p>Pokaż inwestycje:</p>
            <Checkbox className="checkbox--regular">W sprzedaży</Checkbox>
            <Checkbox className="checkbox--regular">Zrealizowane</Checkbox>
          </div> */}
        </div>

        {isLoaded ? (
          <LazyFadeImage>
            <div id="map">
              <GoogleMap
                center={center}
                zoom={isBrowser && window.innerWidth > 991 ? 7 : 6}
                options={{ styles: mapStyle }}
              >
                {investments?.map((item, index) => (
                  <Marker
                    onClick={() => setInfoWindow(item?.id)}
                    onMouseOver={() => setInfoWindow(item?.id)}
                    key={index}
                    icon={withPrefix("/ico-pointer.svg")}
                    position={{
                      lat: item?.acfInvestment?.mapContent?.latitude,
                      lng: item?.acfInvestment?.mapContent?.longitude,
                    }}
                  />
                ))}
                {investments
                  ?.filter(item => item.id === infoWindow)
                  .map((item, index) => (
                    <InfoWindow
                      key={index}
                      onCloseClick={() => setInfoWindow(null)}
                      position={{
                        lat: item?.acfInvestment?.mapContent?.latitude,
                        lng: item?.acfInvestment?.mapContent?.longitude,
                      }}
                    >
                      <InvestmentMapItem item={item} />
                    </InfoWindow>
                  ))}
              </GoogleMap>
            </div>
          </LazyFadeImage>
        ) : null}
      </div>
    </div>
  )
}

export default Map
