import "./styles.scss"

import React from "react"
import Slider from "react-slick"
import scrollToElement from "scroll-to-element"

const Hero = () => {
  const slider = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 750,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          arrows: false,
        },
      },
    ],
  }

  return (
    <section className="home-hero">
      <Slider {...slider}>
        <div>
          <div
            className="home-hero__item"
            style={{
              backgroundImage: `url('${
                require("assets/images/home-hero.jpg").default
              }')`,
            }}
          >
            <div className="container-fluid">
              <h1>Kup wymarzony apartament w miejscowości turystycznej</h1>
            </div>
          </div>
        </div>
        <div>
          <div
            className="home-hero__item"
            style={{
              backgroundImage: `url('${
                require("assets/images/home-hero-2.jpg").default
              }')`,
            }}
          >
            <div className="container-fluid">
              <h1>Zarabiaj na wynajmie apartamentu</h1>
            </div>
          </div>
        </div>
        <div>
          <div
            className="home-hero__item"
            style={{
              backgroundImage: `url('${
                require("assets/images/home-hero-3.jpg").default
              }')`,
            }}
          >
            <div className="container-fluid">
              <h1>Zaoszczędź 23% - odzyskaj VAT</h1>
            </div>
          </div>
        </div>
      </Slider>

      <button
        className="home-hero__scroll"
        onClick={() => scrollToElement("#more")}
      >
        <svg width="44.117" height="56.695" viewBox="0 0 44.117 56.695">
          <path
            d="M-6.277-135.188V-145.61l27.848,11.535L49.418-145.61v10.422L21.571-123.653Zm27.848,32.405,27.847-11.535V-124.74L21.571-113.205-6.277-124.74v10.422Z"
            transform="translate(146.358 49.918) rotate(-90)"
          />
        </svg>
      </button>
    </section>
  )
}

export default Hero
