import "./styles.scss"

import React from "react"

import Button from "components/Button"
import LazyFadeImage from "components/LazyFadeImage"

const Space = () => {
  return (
    <section className="home-space">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-xl-5 col-md-6">
            <LazyFadeImage>
              <div className="home-space__image"></div>
            </LazyFadeImage>
          </div>

          <div className="col-xl-5 offset-xl-1 col-md-6">
            <h2>Odnajdź wymarzoną przestrzeń</h2>
            <p>
              Marzysz o wypoczynku we własnym domu w górach, apartamencie nad
              morzem lub na Mazurach? A może chciałbyś rozpocząć swoją przygodę
              z inwestowaniem w nieruchomości na wynajem? Dobrze trafiłeś!
              Posiadamy szerokie portfolio apartamentów w najbardziej
              atrakcyjnych miejscowościach turystycznych w Polsce.
            </p>
            <p>
              Swoją ofertę adresujemy do osób poszukujących tzw. drugiego domu
              jak i inwestorów oczekujących stabilnych przychodów na atrakcyjnym
              rynku nieruchomości premium.
            </p>
            <Button className="button--bordered" to="/portfel-nieruchomosci/">
              Sprawdź ofertę
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Space
